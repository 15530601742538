<template>
  <div class="orderdetail">
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
    <!-- 头部 -->
    <!-- <Heador :headertitle="headertitle" /> -->
    <van-nav-bar
      title="退货申请"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="101"
      class="dark_bg"
    />
    <!-- 身体 -->
    <div class="corebody">
      <!-- 商品详情兑换内容-->
      <div class="productcore">
        <ul class="myorder-list">
          <li class="dark_shop_bg">
            <div
              v-for="(goods, gindex) in orderDetailInfo.skuList"
              :key="goods.id"
              @click="goGoodsDetail(goods.spuId)"
              style="box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05)"
            >
              <p class="sales-return-title">退款商品</p>
              <van-card
                :num="goods.num"
                :title="goods.spuTitle"
                :thumb="goods.img"
                :desc="goods.title"
                class="dark_shop_bg dark_text shop-card"
              >
                <span slot="price" class="showPriceStyle"
                  >￥{{ (goods.price / 100).toFixed(2) }}</span
                >
                <div slot="footer" v-if="statuscode == 3">
                  <van-button
                    size="mini"
                    type="warning"
                    round
                    plain
                    @click.stop="goComment(gindex)"
                    >去评价</van-button
                  >
                </div>
              </van-card>
              <!-- <p class='sales-return-footer'>{{`不可修改金额，最多￥${(goods.price / 100).toFixed(2)}，含发货邮费￥0.00`}}</p> -->
            </div>
          </li>
        </ul>
        <div class="order-info dark_shop_bg order-text">
          <!-- <p style="color: black; margin-bottom: 0.3rem" class="dark_text"> -->
          <div class="sales-return-title" style="padding: 0">退款原因</div>
          <van-radio-group v-model="retrunOne">
            <div class="retrun-text">
              <span>计划有变，不想要了</span>
              <span
                ><van-radio
                  name="计划有变，不想要了"
                  icon-size=".26rem"
                  checked-color="#EF5C62 "
                ></van-radio
              ></span>
            </div>
            <div class="retrun-text">
              <span>商品拍错了</span>
              <span
                ><van-radio
                  name="商品拍错了"
                  icon-size=".26rem"
                  checked-color="#EF5C62 "
                ></van-radio
              ></span>
            </div>
            <div class="retrun-text">
              <span>地址错误</span>
              <span
                ><van-radio
                  name="地址错误"
                  icon-size=".26rem"
                  checked-color="#EF5C62 "
                ></van-radio
              ></span>
            </div>
            <div class="retrun-text">
              <span>其他原因：</span>
              <input
                type="text"
                v-model="retrunTwo"
                style="width: 75%"
                placeholder="请填写退款原因"
              />
            </div>
          </van-radio-group>
        </div>
      </div>
      <div class="footer">
        <img
          src="@/assets/images/attention.png"
          style="width: 0.26rem; padding: 0 0.1rem"
        />
        退款申请提交后不可撤销，申请成功后，退款至原账户。
      </div>
    </div>
    <!-- 确认兑换 -->
    <!-- <div class="exchange-text"> -->
      <!-- <p @click="goCustomerServer">
        <span class="iconfont icon-kefu"></span>客服
      </p> -->
      <!-- <p
        v-if="orderDetailInfo.status == 10"
        @click="cancelOrder"
        class="inline-text"
        style="
          padding: 0.2rem 0.5rem;
          font-size: 0.3rem;
          background-color: red;
          color: #fff;
        "
      >
        <span class="iconfont icon-jianqu"></span>取消订单
      </p> -->
      <!-- <p v-if="orderDetailInfo.status == 10"> -->
      <!-- <p v-if="orderDetailInfo.status == 10">
        <Btn
          @click.native="goPay()"
          style="
         
            padding: 0.2rem 0.5rem;
            font-size: 0.3rem;
            background-color: red;
            color: #fff;
          "
          >继续支付</Btn
        >
      </p>
      <p
        v-if="
          orderDetailInfo.status == 20 ||
          orderDetailInfo.status == 22 ||
          orderDetailInfo.status == 30 ||
          orderDetailInfo.status == 40 ||
          orderDetailInfo.status == 50
        "
      >
        <Btn
          @click.native="payRefundOrder"
          style="
            padding: 0.2rem 0.5rem;
            font-size: 0.3rem;
            background-color: red;
            color: #fff;
          "
          >申请退款</Btn
        >
      </p>
      <p v-if="orderDetailInfo.status == 30">
        <Btn
          @click.native="finishOrder"
          style="
            padding: 0.2rem 0.5rem;
            font-size: 0.3rem;
            background-color: red;
            color: #fff;
          "
          >确认收货</Btn
        >
      </p> -->
      <!-- <p
        v-if="orderDetailInfo.status == 50"
        style="color: #cf4639"
        class="delete"
        @click="delOrder"
      >
        <span
          class="iconfont icon-shibai"
          style="font-size: 1.4rem; margin: 0 0 0.2rem"
        ></span
        >删除订单
      </p> -->
    <!-- </div> -->

    <div class="bottom-btn">
      <button :disabled="payDisabled" @click="payRefundOrder">申请退款</button>
    </div>
  </div>
</template>

<script>
import Heador from "./header";
import Btn from "../common/btn";
import waybillList from "../public/waybillList";
import { Card, Button, Field, NavBar } from "vant";
export default {
  data() {
    return {
      headertitle: "订单详情",
      count: 1, // 积分数量
      orderDetail: [], // 积分信息
      goodsId: null, // 接收商品id
      icon: [
        "icon-weizhifu",
        "icon-wuliupeisong-",
        "icon-yunshuzhongwuliu-xianxing",
        "icon-yiwancheng",
        "icon-yiquxiao",
        "icon-shibai",
      ],
      statuscode: null, // 总状态值
      statustxt: null, // 总状态描述
      orderId: null, // 订单id
      orderDetailInfo: {},
      isLoading: true,
      goGoodsDetail: this.Base.goGoodsDetail,
      deviceEnvir: this.Base.deviceEnvir(), // 设备环境判断
      message: Object,
      //退货原因
      retrunOne: undefined,

      retrunTwo: undefined,
      locationAddress: "",
      payDisabled: false,
    };
  },
  components: {
    Heador,
    Btn,
    [Card.name]: Card,
    [Field.name]: Field,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    waybillList,
  },
  mounted() {
    this.getOrderDetail();
    // this.getaddres()

    // this.$nextTick(() => {
    //   // this.size = document.documentElement.style.fontSize;
    //   this.size = window.getComputedStyle(document.documentElement)["fontSize"];
    //   console.log(this.size);
    //   let showsize = this.size.split("p")[0];
    //   console.log(showsize);

    //   document.documentElement.style.fontSize = showsize / 3.125 + "px";
    // });

    // this.initOrderList(0);
    let active = this.$route.query.active || "0";
    this.active = active;
  },
  // beforeDestroy() {
  //   document.documentElement.style.fontSize = this.size;
  // },

  methods: {
    getaddres() {
      this.$api.order.getMerchantConfig().then((res) => {
        this.locationAddress = res.data.locationAddress;
      });
    },
    onClickLeft() {
      this.$router.back();
    },
    getOrderDetail() {
      localStorage.orderNo =
        this.$route.params.orderId.id || localStorage.orderNo;

      this.$api.my
        .orderSureAddress({
          orderId: this.$route.params.orderId.id || localStorage.orderNo,
          userId: localStorage.getItem("userid"),
        })
        .then((res) => {
          if (res.errno === 200) {
            this.orderDetailInfo = res.data;
            this.orderDetailInfo.gmtCreate = this.formatDate(
              this.orderDetailInfo.gmtCreate
            );
            this.orderDetailInfo.gmtUpdate = this.formatDate(
              this.orderDetailInfo.gmtUpdate
            );
            this.orderDetailInfo.takeTime = this.formatDate(
              this.orderDetailInfo.takeTime
            );
            // this.orderDetailInfo.codeUrl = this.orderDetailInfo.codeUrl.slice(5)

            this.orderDetailInfo.skuList.forEach((v) => {
              v.title = "  规格 : " + v.title;
            });
            console.log(this.orderDetailInfo);
            sessionStorage.orderNoID = res.data.orderNo;
            sessionStorage.setItem(
              "orderAddress",
              this.orderDetailInfo.province +
                this.orderDetailInfo.city +
                this.orderDetailInfo.county +
                this.orderDetailInfo.address
            );

            // this.orderDetailInfo.discountMoney =
            //   res.returnValue.discountMoney + ".00";
            // sessionStorage.setItem("orderAddress", res.returnValue.address);
            // this.statuscode = res.returnValue.generalStatus;
            let tabarr = [
              "全部",
              "待付款",
              // "等待团购活动结束",
              "待出库",
              // "配货异常",
              "待收货",
              "待评价",
              "完成",
              "退款中",
              "已退款",
              "支付超时",
              "支付异常",
            ];
            let arrnum = [undefined, 10, 20, 30, 40, 50, 60, 70, 90, 100];
            arrnum.forEach((v, i) => {
              if (v === res.data.status) {
                this.statustxt = tabarr[i];
              }
            });
            this.orderStatus = res.data.status;
            // this.orderDetail = res.returnValue.listGoodsOrderDetailVO;
            // this.orderId = res.returnValue.id;
            this.isLoading = false;
          }
        });
    },

    formatDate(cellValue) {
      if (cellValue == null || cellValue == "") return "";
      var date = new Date(cellValue);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    // 支付逻辑，最终与后台交互
    goPay() {
      let orderList = [];
      this.orderDetailInfo.skuList.forEach((val, i) => {
        // if (item.skuList.indexOf(val.id) != -1) {
        orderList.push({
          id: val.id,
          img: val.img, // 商品图片
          title: val.spuTitle, // 商品标题
          skuTitle: val.title, // 商品规格
          num: val.num, //商品数量
          price: val.price, //商品价格
          skuId: val.skuId, //商品规格id
          spuId: val.spuId, //商品id
          isshowcar: false,
          orderNo: val.orderNo,
          takeWay: "cart",
        });
        // }
      });

      localStorage.setItem("skuGoodsList", JSON.stringify(orderList));
      console.log(
        "购物车 取skuGoodsList",
        JSON.parse(localStorage.getItem("skuGoodsList"))
      );
      this.$router.push({
        path: "/orderconfirm",
      });

      // this.$router.push(`/orderconfirm/${id}`);
    },

    // goPay() {
    //   let orderId = this.$route.params.orderId;
    //   // H5支付,(从德合汇跳转到云楚新能源属于H5支付)
    //   const isDehe = localStorage.getItem("isDehe");
    //   // const isDehe = '1';
    //   const openid = localStorage.getItem("openid");
    //   const isHuawei = localStorage.getItem("isHuawei");
    //   const isSalesman = localStorage.getItem("isSalesman");
    //   // d德合汇跳过来的就H5支付
    //   if (isDehe || isHuawei) {
    //     this.$server
    //       .getH5paramsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           this.goPayResult();
    //           switch (this.deviceEnvir) {
    //             case "IOS":
    //               window.webkit.messageHandlers.invokeiOS.postMessage({
    //                 cmd: "010",
    //                 mweb_url: res.returnValue,
    //               });
    //               break;
    //             case "ANDROID":
    //               window.location.href = res.returnValue;
    //               break;
    //           }
    //         } else {
    //           this.$toast(res.message);
    //           this.goPayResult();
    //         }
    //       });
    //     return false;
    //   }
    //   // 理财师跳过来的就H5支付
    //   if (isSalesman) {
    //     this.$server
    //       .getH5paramsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           this.goPayResult();
    //           switch (this.deviceEnvir) {
    //             case "IOS":
    //               window.webkit.messageHandlers.invokeiOS.postMessage({
    //                 cmd: "010",
    //                 mweb_url: res.returnValue,
    //               });
    //               break;
    //             case "ANDROID":
    //               window.location.href = res.returnValue;
    //               break;
    //           }
    //         } else {
    //           this.$toast(res.message);
    //           this.goPayResult();
    //         }
    //       });
    //     return false;
    //   }
    //   // 公众号支付
    //   if (openid) {
    //     // 走公众号支付和充值卡
    //     this.$server
    //       .payAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //         openid: localStorage.getItem("openid") || "",
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           // 代表直接用充值卡就支付完毕
    //           if (res.returnValue == 1) {
    //             this.goPayResult();
    //           } else {
    //             // 充值卡余额不够，走了微信支付
    //             let params = { ...res.returnValue }; // 接收后台的返回值
    //             if (typeof WeixinJSBridge === "undefined") {
    //               if (document.addEventListener) {
    //                 document.addEventListener(
    //                   "WeixinJSBridgeReady",
    //                   onBridgeReady,
    //                   false
    //                 );
    //               } else if (document.attachEvent) {
    //                 document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
    //                 document.attachEvent(
    //                   "onWeixinJSBridgeReady",
    //                   onBridgeReady
    //                 );
    //               }
    //             } else {
    //               this.onBridgeReady(params);
    //             }
    //             this.goPayResult();
    //           }
    //         } else {
    //           this.$toast(res.message);
    //         }
    //       });
    //     return false;
    //   }
    //   // APP支付
    //   if (this.deviceEnvir === "IOS") {
    //     // IOS支付
    //     // 获取支付需要的参数
    //     this.$server
    //       .getParamsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           if (res.returnValue == 1) {
    //             // 直接全部走充值卡支付
    //             this.goPayResult();
    //           } else {
    //             let params = res.returnValue;
    //             // 向IOS发送指令
    //             const paramsObj = { cmd: "009", ...params };
    //             window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
    //             this.goPayResult();
    //           }
    //         } else {
    //           this.$toast(res.message);
    //         }
    //       });
    //     return false;
    //   } else if (this.deviceEnvir === "ANDROID") {
    //     // 安卓支付
    //     // 获取支付需要的参数
    //     this.$server
    //       .getParamsAddress({
    //         accessToken: localStorage.getItem("token"),
    //         orderId,
    //       })
    //       .then((res) => {
    //         if (res.code === 100) {
    //           if (res.returnValue == 1) {
    //             this.goPayResult();
    //             return false;
    //           } else {
    //             let params = res.returnValue;
    //             // 向安卓发送指令
    //             let paramsStr = JSON.stringify({ cmd: "009", ...params });
    //             window.android.invokeAndroid(paramsStr);
    //             this.goPayResult();
    //           }
    //         } else {
    //           this.$toast(res.message);
    //         }
    //       });
    //     return false;
    //   }
    // },
    // 去到询问支付界面
    goPayResult() {
      setTimeout(() => {
        this.$router.replace("/payResult");
      }, 3000);
    },

    // 调起微信支付
    onBridgeReady(params) {
      let that = this;
      WeixinJSBridge.invoke("getBrandWCPayRequest", params, function (res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          that.$router.replace("/myorder");
        } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
          this.goPayResult();
        } else {
          that.$dialog
            .confirm({
              message: "支付失败",
              showCancelButton: false,
            })
            .then(() => {
              this.goPayResult();
            });
        }
      });
    },
    // 去评价
    goComment(index) {
      this.$router.push(`/addComment/${this.$route.params.orderId}/${index}`);
    },
    // 取消订单
    cancelOrder() {
      // let accessToken = localStorage.getItem("token");
      this.$api.my
        .cancelOrderAddress({
          accessToken: localStorage.getItem("token"),
          orderNo: sessionStorage.getItem("orderNoID"),
        })
        .then((res) => {
          if (res.errno == 200) {
            this.$toast({
              message: "取消成功",
              duration: 700,
              onClose: () => {
                this.$router.replace({
                  path: "/myorder",
                  query: {
                    active: "5",
                  },
                });
              },
            });
          } else {
            this.$toast(res.errmsg);
          }
        });

      // this.$server
      //   .cancelOrderAddress({
      //     accessToken,
      //     orderId: this.orderId,
      //   })
      //   .then((res) => {
      //     if (res.returnValue) {
      //       this.$toast({
      //         message: "取消成功",
      //         duration: 1000,
      //         onClose: () => {
      //           this.$router.replace({
      //             path: "/myorder",
      //             query: {
      //               active: "5",
      //             },
      //           });
      //         },
      //       });
      //     } else {
      //       this.$toast(res.message);
      //     }
      //   });
    },
    // 删除订单
    // delOrder() {
    //   this.$server
    //     .delOrderAddress({
    //       accessToken: localStorage.getItem("token"),
    //       orderId: this.orderId,
    //     })
    //     .then((res) => {
    //       if (res.returnValue) {
    //         this.$toast.success({
    //           message: "删除成功",
    //           duration: 1000,
    //           onClose: () => {
    //             this.$router.replace("/myorder");
    //           },
    //         });
    //       } else {
    //         this.$toast(res.message);
    //       }
    //     });
    // },
    // 申请退款
    payRefundOrder() {
      this.payDisabled = true;

      // console.log(this.$route.params.orderId.orderNo);
      // let retrunarr = undefined
      // if(this.retrunOne){
      //   retrunarr = this.retrunOne
      // }
      // if(this.retrunTwo){
      //   if(retrunarr){

      //     retrunarr += '&' + this.retrunTwo
      //   }else{
      //   retrunarr = this.retrunTwo

      //   }
      // }

      this.$api.my
        .payRefundOrderAddress({
          accessToken: localStorage.getItem("token"),
          orderNo: this.$route.params.orderId.orderNo,
          userId: localStorage.getItem("userid"),
          // reason:retrunarr
        })
        .then((res) => {
          if (res.errno === 200) {
            this.$toast.success({
              message: "申请退款成功",
              duration: 700,
              onClose: () => {
                this.$router.replace("/myorder");
              },
            });
          } else {
            this.$toast(res.errmsg);
            this.payDisabled = false;
          }
        });
      // this.$server
      //   .payRefundOrderAddress({
      //     accessToken: localStorage.getItem("token"),
      //     orderId: this.orderId,
      //   })
      //   .then((res) => {
      //     if (res.code === 100 && res.returnValue) {
      //       this.$toast.success({
      //         message: "申请退款成功",
      //         duration: 700,
      //         onClose: () => {
      //           this.$router.replace("/myorder");
      //         },
      //       });
      //     } else {
      //       this.$toast(res.message);
      //     }
      //   });
    },
    // 完成订单
    finishOrder() {
      this.$api.my
        .orderFinishAddress({
          accessToken: localStorage.getItem("token"),
          orderNo: sessionStorage.getItem("orderNoID"),
        })
        .then((res) => {
          if (res.errno === 200) {
            this.$toast("订单完成");

            this.$router.replace("/myorder");
          } else {
            this.$toast(res.errmsg);
          }
        });
      // .then((res) => {
      //   if (res.code === 100 && res.returnValue === 1) {
      //     this.$toast("订单完成");
      //   } else {
      //     this.$toast(res.message);
      //   }
      // });
    },
    // 去到客服
    // goCustomerServer() {
    //   this.$router.push({
    //     path: "/customerChat",
    //     query: { orderId: this.$route.params.orderId },
    //   });
    // },
  },
  computed: {
    total() {
      var moneyTotal = 0,
        scoreTotal = 0;
      this.orderDetail.forEach((v, i) => {
        moneyTotal += v.amount * v.money;
        scoreTotal += v.amount * v.score;
      });
      return `￥${moneyTotal}+${scoreTotal}积分`;
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../less/element.less";
@red: @ordinary_btn_color;

.orderdetail {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0.76rem 0 1.28rem;
  background-color: #f8f8f8;

  .van-nav-bar {
    .van-icon {
      color: #ccc;
      font-size: 0.44rem;
    }
    .van-nav-bar__title {
      // color: #d84b41;
      letter-spacing: 2px;
    }
  }
  .corebody {
    padding: 0.32rem;
    & > section {
      padding: 0.32rem 0.32rem;
      background: #fff;
      margin-bottom: 0.32rem;
      border-radius: 0.06rem;
      .list(row);
      justify-content: space-between;
      align-items: center;
      ul {
        width: 70%;
        li {
          font-size: 0.28rem;
          line-height: 0.44rem;
          &:first-child {
            span {
              &:first-child {
                color: black;
              }
              &:last-child {
                color: rgb(156, 155, 155);
              }
            }
          }
          &:last-child {
            color: @red;
            font-size: 0.28rem;
          }
        }
      }
      & > span {
        font-size: 0.64rem;
        color: @red;
      }
      & > p {
        font-size: 0.24rem;
        text-align: center;
        color: @ordinary_btn_color;
        span {
          display: block;
          font-size: 0.5rem;
          line-height: 0.64rem;
        }
      }
    }
    & > .productcore {
      margin-bottom: 0.32rem;
      .myorder-list {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        li {
          background-color: #fff;
          border-radius: 0.06rem;
          .van-card {
            background: #fff;
            border-radius: 0.06rem 0.06rem 0 0;
          }
          .van-cell {
            background: #fff;
            font-size: 0.24rem;
          }
          .moneyInfo {
            padding: 0.12rem 0.24rem;
            font-size: 0.24rem;
            p {
              .list(row);
              line-height: 0.44rem;
              justify-content: space-between;
            }
          }
        }
      }
      & > div.order-info {
        margin: 0.32rem 0 0.32rem;
        padding: 0.32rem 0.24rem;
        background: #fff;
        box-sizing: border-box;
        border-radius: 0.06rem;
        p {
          .list(row);
          line-height: 0.44rem;
          font-size: 0.32rem;
          color: rgb(139, 138, 138);
          &:first-child::before {
            display: inline-block;
            width: 2px;
            height: 14px;
            content: "";
            background-color: @ordinary_btn_color;
            margin-right: 0.16rem;
          }
        }
        & > p:not(:first-child) {
          font-size: 0.28rem;
          text-indent: 16px;
          span {
            &:first-child {
              color: rgb(83, 83, 83);
            }
          }
        }
      }
      & > p {
        margin: 0.32rem 0 0;
        padding: 0.32rem 0 0;
        border-top: 1px solid #ccc;
        span {
          color: @red;
        }
      }
    }
  }
  & > .exchange {
    .list(row);
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    z-index: 99;
    padding: 0.38rem;
    // justify-content: space-between;
    justify-content: space-around;
    align-items: center;
    background-color: #f0ebe8;
    bottom: 0;
    left: 0;
    p {
      color: @red;
      font-size: 0.24rem;
      letter-spacing: 1px;
      margin: 0 0 0 0.24rem;
      &:first-child {
        span {
          display: block;
          font-size: 0.53rem;
        }
      }
      &:nth-child(2) {
        color: @ordinary_btn_color;
        span {
          font-size: 0.53rem;
          text-align: center;
          display: block;
        }
      }
      &:last-child {
        .btn {
          .btnmin(
            @padding:0.24rem 1.6rem,
            @size:0.28rem,
            @radius:0.06rem,
            @color:#fff
          );
          background: @ordinary_btn_color;
        }
      }
    }
  }
}
</style>
<style lang='less' scoped>
.addcardMask {
  .van-dialog__header {
    padding-left: 0.53rem;
    text-align: left;
    color: #7d7e80;
  }
  .van-dialog__confirm {
    // color: #c83f3f;
  }
}
.inline-text {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.delete {
  display: flex;
  align-items: center;
}
.exchange-text {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.van-card__price {
  font-size: 0.28rem;
}
.shop-card {
  padding: 0.36rem 0.46rem 0.31rem 0.3rem;
  // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: none;
  border-radius: 10px;
  box-sizing: content-box;
  line-height: 1.62rem;
}
.van-card__title {
  font-size: 0.26rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.36rem !important;
  max-height: 0.72rem;
}
.van-card__desc {
  margin: 0.12rem 0 0.35rem;
  font-size: 0.26rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #aeaeae;
  line-height: 0.36rem;
}
.van-card__thumb {
  width: 1.62rem;
  height: 1.62rem;
}

.orderdetail
  .corebody
  > .productcore
  > div.order-info
  > p:not(:first-child)
  span[data-v-18f60038]:first-child {
  font-size: 0.26rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #7e7e7e;
  line-height: 0.49rem;
}
.orderdetail .corebody > .productcore > div.order-info p[data-v-18f60038] {
  font-size: 0.26rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.49rem;
}
.ewm {
  padding: 0.39rem 50% 0;
  > img {
    width: 2.3rem;
    transform: translateX(-50%);
  }
}
.sales-return-title {
  padding: 0.39rem 0 0 0.22rem;
  height: 0.27rem;
  font-size: 0.28rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 0.49rem;
}
.sales-return-footer {
  padding: 0.08rem 0 0.36rem 0.22rem;
  height: 0.23rem;
  font-size: 0.22rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #aeaeae;
  line-height: 0.49rem;
  border-top: 1px solid #f4f4f4;
}
.order-text {
  font-size: 0.26rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.49rem;
}
.retrun-text {
  display: flex;
  justify-content: space-between;
  margin-top: 0.36rem;
}
.footer {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 0.49rem;
}
.bottom-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.3rem;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(7, 63, 53, 0.1);
  > button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 86%;
    height: 0.9rem;
    background: @ordinary_btn_color;
    border-radius: 0.1rem;

    font-weight: 500;
    color: #ffffff;
    font-size: 0.36rem;
  }
}
.showPriceStyle {
  color: @price_text_color;
}
</style>
